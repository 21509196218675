import { identify, track } from '@parsleyhealth/cilantro-track';
import { navigate } from '@reach/router';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Form as FormWrapper } from 'react-final-form';
import { useContainer } from 'unstated-next';
import { Caption } from '@ph-bit/design.ui.typography.caption';
import ScreenButton from '../molecules/screen-button';
import { getProductProps } from '../../lib/products';
import {
  useCart,
  useEntries,
  useNavigate,
  useUpdateEntries,
  useWaitFor,
  useUpdateCart,
  Loader,
  Session,
  usePageTitle,
  useAllPlans
} from '../../state';
import { WelcomeFormData } from '../../types';
import Column from '../atoms/column';
import { H1 } from '@ph-bit/design.ui.typography.h1';
import EmailField from '../molecules/email-field';
import ScreenWrapper from '../molecules/screen-wrapper';
import ZipField from '../molecules/zip-field';
import { InsuranceField } from '../molecules/insurance-field';
import { getStateForZipCode_getStateForZipCode } from '../../api/generated-types/getStateForZipCode';
import { PapiInsuranceType } from '../../types/papi-global-types';
import { useAvailableInsuranceChanges } from '../../api/use-get-plan-transitions';
import { getPlanForProductSelection } from '../../lib/products';
import { AnyProductSelection } from '../../types';
import { InsurancePayer } from '../../types';
import { useGclid } from '../../lib/use-gclid';
import withStyles from '../../hoc/with-styles';
import { ERROR_PATH } from '../../constants/route-names';
import { SeparatedTerms } from '../molecules/separated-terms';
import {
  QUESTIONS_PHONE_NUMBER,
  WWW_URL
} from '../../constants/external-links';
import { TermsLink } from '../atoms/terms-link';
import { formatPhoneNumber } from '../../utils/format-phone-number';
// import { Link } from '@ph-bit/design.ui.buttons.link';

export type StateState = getStateForZipCode_getStateForZipCode;

const Welcome: React.FC = () => {
  usePageTitle('Get started');
  const { entries } = useEntries();
  const { startLoad } = useContainer(Loader);
  const session = useContainer(Session);
  const { saveEntries } = useUpdateEntries();
  const [state, setState] = useState<StateState | undefined>(undefined);
  const [ineligibilityModal, setIneligibilityModal] = useState<boolean>(false);
  const { cartItems } = useCart();
  const { next, goTo } = useNavigate();
  const product = cartItems.length > 0 ? cartItems[0].productKey : undefined;
  const { editCartItem } = useUpdateCart();
  const { data: insuranceData } = useAvailableInsuranceChanges(
    cartItems[0]?.planID
  );
  const billingPeriod =
    insuranceData?.listAvailableInsuranceChanges[0]?.billingPeriod || undefined;
  const allPlansResult = useAllPlans();
  // Do we know on this step if the user is isInNetwork?
  const isInNetwork = entries.insuranceType === PapiInsuranceType.IN_NETWORK;
  const comingFromPlanRoute = entries.comingFromPlanRoute;
  // const employerCheckURL =
  //   'https://enterprise.parsleyhealth.com/find-my-employer';

  useWaitFor([]);

  const gclid = useGclid();

  const abbreviation = state?.abbreviation || '';
  const submit = useCallback(
    async (data: WelcomeFormData) => {
      if (!cartItems[0] && comingFromPlanRoute) {
        console.error('You must have a cart item to continue');
        navigate(ERROR_PATH);
      }

      const params = {
        billingPeriod,
        insuranceType: PapiInsuranceType.CASH_PAY
      };

      const getAvailablePlan = (): any => {
        if (!allPlansResult?.data) {
          return;
        }

        if (!product) {
          return;
        }

        const plan = getPlanForProductSelection(
          {
            productKey: cartItems[0].productType,
            params
          } as AnyProductSelection,
          allPlansResult.data
        );

        return plan;
      };

      try {
        const isInNY = abbreviation === 'NY';
        const isInCA = abbreviation === 'CA';
        const hasInsurance = data.insurancePayer !== InsurancePayer.OTHER;

        if (
          !ineligibilityModal &&
          isInNetwork &&
          ((!isInNY && !isInCA) || !hasInsurance)
        ) {
          setIneligibilityModal(true);
          track(`Sees Not Eligible for Aetna NY`, {
            label: data.insurancePayer!,
            category: 'Impression'
          });

          return;
        }

        if (ineligibilityModal && !comingFromPlanRoute) {
          const plan = getAvailablePlan();

          if (!plan) {
            return;
          }

          editCartItem(cartItems[0].id, {
            ...cartItems,
            params,
            planID: plan.id,
            planName: plan.displayName
          });

          track('Continue Sign Up', {
            label: data.insurancePayer!,
            category: 'Clicked CTA'
          });
        }

        const stateCode = data.stateCode;
        if (!stateCode) {
          throw Error('State code must be available on submit.');
        }

        await startLoad();

        const acceptedAtDate = new Date().toISOString();
        const terms = {
          privacyPolicyAcceptedAt: acceptedAtDate,
          termsOfUseAcceptedAt: acceptedAtDate,
          telehealthInformedConsentAcceptedAt: acceptedAtDate,
          telehealthAgreementAcceptedAt: acceptedAtDate,
          noticePrivacyPracticesAcceptedAt: acceptedAtDate
        };

        const initialData = {
          email: data.email,
          addressPostalCode: data.addressPostalCode,
          stateCode,
          insuranceType:
            entries.insuranceType === PapiInsuranceType.IN_NETWORK &&
            !ineligibilityModal
              ? PapiInsuranceType.IN_NETWORK
              : PapiInsuranceType.CASH_PAY,
          insurancePayer: entries.insurancePayer,
          ignoreScreens: [],
          ...terms
        };

        // If there is no existing session record for the user, create one
        await session.createSession(initialData);
        await saveEntries(initialData);

        const TrackingParams: {
          state: string;
          zipCode: string;
          product?: string;
        } = {
          state: stateCode,
          zipCode: data.addressPostalCode
        };

        if (product) {
          TrackingParams.product = getProductProps(product).productType;
        }

        identify(TrackingParams);
        track('Zip Email Completed', {
          state: stateCode,
          zip: data.addressPostalCode,
          category: 'EnhancedEcommerce',
          label: isInNetwork && !ineligibilityModal ? data.insurancePayer : ''
        });

        if (!data.email.includes('e2e-test')) {
          identify({
            email: data.email,
            started_checkout: true,
            state: TrackingParams.state,
            zipCode: TrackingParams.zipCode,
            product_selected: TrackingParams?.product,
            membership_status: 'PROSPECTIVE',
            insurance:
              isInNetwork && !ineligibilityModal ? data.insurancePayer! : '',
            gclid: gclid ? gclid : undefined,
            ...terms
          });
          window.heap.identify(data.email);
        }

        if (TrackingParams.product) {
          track('Started with product', { product: TrackingParams.product });
        }
        track('Checkout Step Completed', {
          step: 1
        });

        comingFromPlanRoute ? goTo('checkout') : next();
      } catch (err) {
        startLoad('error');
        track('Join Flow Error', {
          product: undefined,
          description: `Welcome page  ${err}`
        });
      }
    },
    [
      cartItems,
      comingFromPlanRoute,
      billingPeriod,
      allPlansResult.data,
      product,
      abbreviation,
      ineligibilityModal,
      isInNetwork,
      startLoad,
      entries.insuranceType,
      entries.insurancePayer,
      session,
      saveEntries,
      goTo,
      next,
      editCartItem,
      gclid
    ]
  );

  const shouldShowInsuranceField = false;

  const TrackingParamsGA = useMemo(() => {
    return {
      products: [
        product && {
          id: cartItems[0].id,
          name: cartItems[0].planName,
          product: cartItems[0].productType,
          category: 'EnhancedEcommerce',
          variant: Boolean(cartItems[0].isWaitlisted)
        }
      ],
      currency: 'USD',
      label: isInNetwork ? entries.insurancePayer : null
    };
  }, [cartItems, isInNetwork, product, entries.insurancePayer]);

  useEffect(() => {
    track('Checkout Step Viewed', {
      step: 1
    });
  }, [TrackingParamsGA]);

  return (
    <ScreenWrapper testid="welcome">
      <Column>
        <div className="sm:ph-text-center ph-mb-7">
          <H1 className="ph-mb-8 ph-text-3xl md:ph-text-4xl">
            Let’s get started
          </H1>
          <p className="ph-leading-relaxed">
            {isInNetwork
              ? 'We just need a few details to verify your eligibility for in-network coverage.'
              : 'We need to make sure we are licensed in your state.'}
          </p>
        </div>
        <FormWrapper<WelcomeFormData>
          initialValues={
            {
              email: entries.email,
              addressPostalCode: entries.addressPostalCode,
              stateCode: entries.stateCode
            } as WelcomeFormData
          }
          onSubmit={submit}
        >
          {({ handleSubmit, invalid, hasSubmitErrors, validating, values }) => {
            return (
              <form>
                <EmailField name="email" tabIndex={1} className="ph-mb-10" />
                <ZipField
                  disabled={validating && !values.addressPostalCode}
                  name="addressPostalCode"
                  helper="Where you will be located when seeing your doctor"
                  setState={setState}
                  state={state}
                  tabIndex={2}
                  className="ph-mb-10"
                />
                {shouldShowInsuranceField && (
                  <InsuranceField name="insurancePayer" className="ph-mb-10" />
                )}
                <div className="flex flex-col center mb-10">
                  <ScreenButton
                    onClick={handleSubmit}
                    disabled={invalid || validating}
                  />
                  {/* <H1 className="text-base md:ph-text-xl ph-text-center mt-5">
                    Is your employer paying for Parsley Health?
                  </H1>
                  <Link
                    size="l"
                    className="m-auto mt-3"
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      window.location.href = employerCheckURL;
                    }}
                  >
                    Activate Membership
                  </Link> */}
                </div>
                <div
                  data-testid="terms-checkbox"
                  className="md:ph-text-center ph-text-left"
                >
                  <Caption>Clicking ‘Continue’ means you agree to our </Caption>
                  <SeparatedTerms
                    separator="&"
                    terms={[
                      {
                        href: `${WWW_URL}/terms-of-use`,
                        label: 'Terms of Use'
                      },
                      {
                        href: `${WWW_URL}/privacy`,
                        label: 'Privacy Policy'
                      }
                    ]}
                  />
                  <Caption className="md:ph-text-center ph-text-left">
                    {' '}
                    and to receive emails from Parsley Health.
                  </Caption>
                </div>
                <div
                  data-testid="questions-call"
                  className="md:ph-text-center ph-text-left"
                >
                  <Caption>Questions? Call </Caption>
                  <TermsLink
                    href={`tel:+1${QUESTIONS_PHONE_NUMBER}`}
                    label={formatPhoneNumber(QUESTIONS_PHONE_NUMBER)}
                  />
                </div>
                {hasSubmitErrors ? (
                  <p>Whoops there was a problem. Try again.</p>
                ) : null}
              </form>
            );
          }}
        </FormWrapper>
      </Column>
    </ScreenWrapper>
  );
};

export default withStyles(Welcome);
