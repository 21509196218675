/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum EligibilityCheckStatus {
  CERTIFIED = "CERTIFIED",
  UNCERTIFIED = "UNCERTIFIED",
}

export enum PapiAgreementType {
  ASSIGNMENT_OF_BENEFITS = "ASSIGNMENT_OF_BENEFITS",
  CLINICAL_MEMBERSHIP = "CLINICAL_MEMBERSHIP",
  CREDIT_CARD_AUTH = "CREDIT_CARD_AUTH",
  HIPAA_AUTHORIZATION = "HIPAA_AUTHORIZATION",
  MEMBERSHIP_AGREEMENT = "MEMBERSHIP_AGREEMENT",
  MEMBERSHIP_TERMS = "MEMBERSHIP_TERMS",
  NOTICE_PRIVACY_PRACTICES = "NOTICE_PRIVACY_PRACTICES",
  PRIVACY_POLICY = "PRIVACY_POLICY",
  SELF_PAYMENT_OF_SERVICE = "SELF_PAYMENT_OF_SERVICE",
  SUPPLEMENT = "SUPPLEMENT",
  TELEHEALTH_INFORMED_CONSENT = "TELEHEALTH_INFORMED_CONSENT",
  TERMS_OF_USE = "TERMS_OF_USE",
}

export enum PapiBillingPeriod {
  DAY = "DAY",
  HALF_YEAR = "HALF_YEAR",
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  TRIMESTER = "TRIMESTER",
  YEAR = "YEAR",
}

export enum PapiInsuranceType {
  CASH_PAY = "CASH_PAY",
  IN_NETWORK = "IN_NETWORK",
  OUT_OF_NETWORK = "OUT_OF_NETWORK",
  PARTNER_BILLING = "PARTNER_BILLING",
}

export enum PapiMembershipType {
  ENTRY = "ENTRY",
  RENEWAL = "RENEWAL",
  TRIAL = "TRIAL",
  WAITLIST = "WAITLIST",
}

export enum PapiPaidLatestInvoiceStatus {
  EXPIRED = "EXPIRED",
  IN_ARREARS = "IN_ARREARS",
  PAID = "PAID",
}

export enum PapiPaymentStatus {
  DRAFT = "DRAFT",
  IN_ARREARS = "IN_ARREARS",
  OPEN = "OPEN",
  PAID = "PAID",
  PENDING = "PENDING",
  UNCOLLECTIBLE = "UNCOLLECTIBLE",
  UNKNOWN = "UNKNOWN",
  VOID = "VOID",
}

export enum PapiProductType {
  ASSESSMENT = "ASSESSMENT",
  BASIC_HORMONE_ANALYSIS = "BASIC_HORMONE_ANALYSIS",
  COMPLETE_CARE_RENEWAL = "COMPLETE_CARE_RENEWAL",
  COMPLETE_CARE_Y1 = "COMPLETE_CARE_Y1",
  COMPLETE_CARE_Y1_POST_ASSESSMENT = "COMPLETE_CARE_Y1_POST_ASSESSMENT",
  COMPLETE_CARE_Y2_PLUS = "COMPLETE_CARE_Y2_PLUS",
  COMPREHENSIVE_GUT_ANALYSIS = "COMPREHENSIVE_GUT_ANALYSIS",
  COMPREHENSIVE_GUT_MEMBERSHIP = "COMPREHENSIVE_GUT_MEMBERSHIP",
  COMPREHENSIVE_HORMONE_ANALYSIS = "COMPREHENSIVE_HORMONE_ANALYSIS",
  COMPREHENSIVE_HORMONE_MEMBERSHIP = "COMPREHENSIVE_HORMONE_MEMBERSHIP",
  COMPREHENSIVE_HORMONE_PLAN = "COMPREHENSIVE_HORMONE_PLAN",
  ESSENTIAL_CARE = "ESSENTIAL_CARE",
  EXTENSION = "EXTENSION",
  FOLLOW_UP_ASSESSMENT = "FOLLOW_UP_ASSESSMENT",
  FOUNDATION = "FOUNDATION",
  FOUNDING_MEMBERSHIP = "FOUNDING_MEMBERSHIP",
  FREE_TRIAL = "FREE_TRIAL",
  HEALTH_RISK_ASSESSMENT = "HEALTH_RISK_ASSESSMENT",
  HORMONE_MEMBERSHIP = "HORMONE_MEMBERSHIP",
  LABS_PLUS_COACHING = "LABS_PLUS_COACHING",
  MEDICAL_ONLY = "MEDICAL_ONLY",
  MEDICAL_PLUS_COACHING = "MEDICAL_PLUS_COACHING",
  MENOPAUSE_LIFECYCLE_HORMONE_ANALYSIS = "MENOPAUSE_LIFECYCLE_HORMONE_ANALYSIS",
  MENOPAUSE_LIFECYCLE_HORMONE_MEMBERSHIP = "MENOPAUSE_LIFECYCLE_HORMONE_MEMBERSHIP",
  MERIWETHER = "MERIWETHER",
  NEW_PROGRAMS_LITE = "NEW_PROGRAMS_LITE",
  NO_PRODUCT = "NO_PRODUCT",
  PEDIATRICS = "PEDIATRICS",
  PEDIATRICS_RENEWAL = "PEDIATRICS_RENEWAL",
  SHORT_PROGRAM = "SHORT_PROGRAM",
  TOTAL_HEALTH = "TOTAL_HEALTH",
  VIRTUAL_ASSESSMENT = "VIRTUAL_ASSESSMENT",
  VIRTUAL_COMPLETE_CARE_Y1 = "VIRTUAL_COMPLETE_CARE_Y1",
  VIRTUAL_COMPLETE_CARE_Y2_PLUS = "VIRTUAL_COMPLETE_CARE_Y2_PLUS",
  WAITLIST_ASSESSMENT = "WAITLIST_ASSESSMENT",
  WAITLIST_COMPLETE_CARE = "WAITLIST_COMPLETE_CARE",
  WAITLIST_PEDIATRICS = "WAITLIST_PEDIATRICS",
  WAITLIST_SHORT_PROGRAM = "WAITLIST_SHORT_PROGRAM",
  WAITLIST_VIRTUAL_ASSESSMENT = "WAITLIST_VIRTUAL_ASSESSMENT",
  WAITLIST_VIRTUAL_COMPLETE_CARE = "WAITLIST_VIRTUAL_COMPLETE_CARE",
}

export enum PapiRole {
  PARSLEY_ADMIN = "PARSLEY_ADMIN",
  PARSLEY_ANONYMOUS = "PARSLEY_ANONYMOUS",
  PARSLEY_MEMBER = "PARSLEY_MEMBER",
  PARSLEY_STAFF = "PARSLEY_STAFF",
  PARSLEY_SUPERADMIN = "PARSLEY_SUPERADMIN",
  PARSLEY_USER = "PARSLEY_USER",
}

export enum PapiSex {
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
}

export enum PasswordValidateErrorCode {
  HAVE_BEEN_PWNED = "HAVE_BEEN_PWNED",
  LENGTH_ERROR = "LENGTH_ERROR",
}

export interface EligibilityCheckInput {
  state: string;
  payerId: string;
  firstName: string;
  lastName: string;
  dob: string;
  memberId?: string | null;
}

export interface PapiCreatePersonInput {
  email: string;
  firstName: string;
  middleName?: string | null;
  lastName: string;
  dateOfBirth: string;
  biologicalSex: PapiSex;
  genderIdentity?: string | null;
  ssn?: string | null;
  phoneNumber?: string | null;
  centerID: string;
  addressLine1?: string | null;
  addressLine2?: string | null;
  addressCity?: string | null;
  addressState?: string | null;
  addressPostalCode: string;
  patientAgreementAcceptedAt?: string | null;
  insurancePayer?: string | null;
  insuranceGroupID?: string | null;
  insuranceMemberID?: string | null;
  hipaaAuthorizationAcceptedAt?: string | null;
  termsOfUseAcceptedAt?: string | null;
  membershipTermsAcceptedAt?: string | null;
  selfPaymentOfServicesTermsAcceptedAt?: string | null;
  privacyPolicyAcceptedAt?: string | null;
  supplementAcceptedAt?: string | null;
  clinicalMembershipAgreementAcceptedAt?: string | null;
  membershipAgreementAcceptedAt?: string | null;
  assignmentOfBenefitsAcceptedAt?: string | null;
  creditCardAuthAcceptedAt?: string | null;
  role?: PapiRole | null;
  sendPasswordReset: boolean;
  recaptcha?: PapiRecaptchaInput | null;
  noticePrivacyPracticesAcceptedAt?: string | null;
  telehealthInformedConsentAcceptedAt?: string | null;
  telehealthAgreementAcceptedAt?: string | null;
  assignedDoctorID?: string | null;
  assignedCoachID?: string | null;
  onboardingFlowSlug?: string | null;
  analyticsID?: string | null;
  enrolledBy?: string | null;
}

export interface PapiFollowOnPurchaseInput {
  planID: string;
  centerID: string;
  couponSlug?: string | null;
}

export interface PapiMakeNewPurchaseInput {
  payorID: string;
  planID: string;
  centerID: string;
  startTime?: string | null;
  couponSlug?: string | null;
  personID: string;
  followOnPurchase?: PapiFollowOnPurchaseInput | null;
  addDefaultFollowOn?: boolean | null;
  iterateOverFollowOns?: boolean | null;
}

export interface PapiRecaptchaInput {
  token: string;
  ipAddress: string;
}

export interface PapiRegisterPaymentCardWithPersonInput {
  personID: string;
  name: string;
  last4Digits: string;
  expiryMonth: number;
  expiryYear: number;
  stripeSourceToken: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
