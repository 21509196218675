import React, { useEffect } from 'react';
import { Router, RouteComponentProps, globalHistory } from '@reach/router';
import JoinProvider from './components/providers/join-provider';
import StripeWrapper from './components/providers/stripe-wrapper';
import Bootstrap from './components/organisms/bootstrap';
import ScreenRoutes from './components/organisms/screen-routes';
import Buy from './components/organisms/buy';
import Plan from './components/organisms/plan';
import Error from './components/screens/error';
import Loader from './state/loader';
import LoaderOverlay from './components/atoms/loader-overlay';
import Maintenance from './components/organisms/maintenance';
import { NotFound } from './components/templates';
import { REGISTER_PATH, BUY_PATH, PLAN_PATH } from './constants/route-names';
import { NAME, VERSION, COMMIT_SHA } from './constants/env';
import track from '@parsleyhealth/cilantro-track';
import { useCart } from './state';
import { JOIN_PARSLEY_URL } from './constants/external-links';
import { page } from '@parsleyhealth/cilantro-track';
import { useNewProgram, newProgramRedirect } from '@parsleyhealth/cilantro-ui';

window.APP_NAME = NAME;
window.APP_VERSION = VERSION;
window.BUNDLE_COMMIT_SHA = COMMIT_SHA;

const referrer = document.referrer;

const Register: React.FC<RouteComponentProps> = () => {
  const { cartItems } = useCart();

  useEffect(() => {
    track('Checkout Started', {
      referrer: referrer,
      label: 'Aetna NY',
      category: 'EnhancedEcommerce'
    });
    globalHistory.listen(() => {
      page();
    });
  }, []);

  // This validation's purpose is to check if you have selected a product
  // If not we redirect you back to the website.
  if (cartItems.length === 0) {
    window.location.href = JOIN_PARSLEY_URL;
    return null;
  }

  return (
    <StripeWrapper>
      <ScreenRoutes />
    </StripeWrapper>
  );
};

const App: React.FC = () => {
  const { isAllowed, isLoading, isDisabled } = useNewProgram(true);
  useEffect(() => {
    if (isDisabled) {
      return;
    }

    if (!isAllowed) {
      newProgramRedirect(true);
    }
  }, [isAllowed, isDisabled, isLoading]);

  return (
    <Loader.Provider>
      <LoaderOverlay />
      {isAllowed && (
        <JoinProvider>
          <Maintenance>
            <Bootstrap>
              <Router>
                <Error path="error" />
                <Buy path={`${BUY_PATH}/:product`} />
                <Plan path={`${PLAN_PATH}/:planId`} />
                <Register path={`${REGISTER_PATH}/*`} />
                <NotFound default />
              </Router>
            </Bootstrap>
          </Maintenance>
        </JoinProvider>
      )}
    </Loader.Provider>
  );
};

export default App;
